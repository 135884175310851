@import url("base.css");
body{
	padding-top: 80px;
}
.modal-open .header{
	padding-right: 17px;
}
.header{
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #e6e6e6;
    box-shadow: 0 1px 10px rgba(0,0,0,.17);
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    z-index: 1000;
}
.header .container{
	 padding-left: 0;
	 padding-right: 0;
	 display: table;
	 position: relative;
}
.header .logo{
	display: table-cell;
	vertical-align: middle;
	/*top: 22px;
    left: 12px;*/
    /*position: fixed;*/
    z-index: 6;
    width: 192px;
    /*height: 48px;*/
    -moz-transition: all .1s ease-in;
    -o-transition: all .1s ease-in;
    -webkit-transition: all .1s ease-in;
    transition: all .1s ease-in;
}
.header .logo img{
	width: 100%;
	/*margin-top: -6px;*/
}


/*.header-bottom .nav-border-bottom>li>a:hover {
    border-bottom: 2px solid #337ab7;
}*/
.header-bottom .nav-border-bottom>li:hover>a {
    border-bottom: 2px solid #337ab7;
}
.header-top {
	overflow: hidden;
    position: relative;
    -moz-transition: all .1s ease-in;
    -o-transition: all .1s ease-in;
    -webkit-transition: all .1s ease-in;
    transition: all .1s ease-in;
    /*border-bottom: 1px solid #ddd;*/
    padding: 0;
    padding-top: 5px !important;
    padding-left: 20px;
}
.header-bottom {
	padding-top: 0px;
	padding-left: 20px;
	/*position: relative;*/
}
.header-top .nav a{
	font-size: 14px;
	padding-left: 18px;
	padding-right: 18px;
}
.header-top .nav-tabs>li>a:hover{
	background: #eee;
}
.header-bottom .menu-top .nav{
	margin-left: 15px;
}
.header-bottom .nav>li{
	margin-right: 42px;
	position: relative;
}
.header-bottom .nav>li:last-child{
	margin-right: 0;
}
.header-bottom .nav a{
	font-size: 16px;
	padding: 15px 0;
}
.header-bottom .nav>li.active>a,
.header-bottom .nav>li.active>a:focus,
.header-bottom .nav>li.active>a:hover {
    font-weight: 300;
    color: #406eb3;
}

/*features*/

.header-bottom .nav .features-wrap{position: static;}
.header-bottom .features-wrap.open > a,
.header-bottom .features-wrap.open > a:hover,
.header-bottom .features-wrap.open > a:focus{
	background-color: #fff;
}

.header-bottom  .features-menu{
	width: 100%;
}
.header-bottom .dropdown-open>li.active>a{
    color: #333;
    border-bottom: 2px solid transparent;
}
.header-bottom .dropdown-open>li.features-wrap.open>a{
    color: #406eb3;
    border-bottom: 2px solid #337ab7;
}

/*features end*/

/*workflow-steps-container*/
.workflow-steps-container {
 	padding: 30px 15px
 }
 .workflow-steps-container ul.nav-tabs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0;
    border: 0;
}
  .workflow-steps-container li{
  	margin-right: 0 !important;
  }
 .workflow-steps-container li:not(.new-style) {
    width: 20%;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    list-style: none;
    position: relative;
}
.workflow-steps-container li:not(.new-style) a.step .step-indicator {
    font-size: 0.813em;
    font-weight: 500;
    color: #8aa2ad;
    font-style: italic;
    text-transform: uppercase;
    cursor: pointer;
}
.workflow-steps-container li:not(.new-style) a.step h3.title {
    font-size: 14px;
    color: #465862;
    margin: 10px 0;
    padding-bottom: 10px;
    border-bottom: 3px solid transparent;
    -webkit-transition: all;
    transition: all;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    display: inline-block;
    letter-spacing: 0.3px;
    cursor: pointer;
}
.workflow-steps-container li:not(.new-style) a.step h3.title p{
	margin-top: 10px;
}
.workflow-steps-container li:not(.new-style).workflow-line {
    width: 15px;
    max-width: 15px;
}
.workflow-steps-container li:not(.new-style).workflow-line .line {
    width: 15px;
    height: 2px;
    border-radius: 2px;
    background: #98b1bd;
    opacity: 0.7;
    position: relative;
    top: 88px;
}


.workflow-steps-container .nav-tabs li a.step {
 	text-align: center;
 	border: 0;
 	margin: 0;
 	padding: 0;
 	cursor: pointer
 }
 
.workflow-steps-container .nav-tabs li a.step h3.title {
 	color: #465862
 }
 
.workflow-steps-container .nav-tabs li a.step .icon {
 	position: relative;
 	height: 50px
 }
 
.workflow-steps-container .nav-tabs li a.step .icon img {
 	position: absolute;
 	left: 0;
 	right: 0;
 	top: 0;
 	bottom: 0;
 	margin: 0 auto;
 	height: 35px;
 }
 
.workflow-steps-container .nav-tabs li a.step .icon img.inactive {
 	opacity: 1
 }
 
.workflow-steps-container .nav-tabs li a.step .icon img.active {
 	opacity: 0
 }
 
.workflow-steps-container .nav-tabs li a.step:hover .icon img.inactive {
 	opacity: 0
 }
 
.workflow-steps-container .nav-tabs li a.step:hover .icon img.active {
 	opacity: 1;
 	-webkit-transform: scale(1);
 	-ms-transform: scale(1);
 	transform: scale(1)
 }
 
.workflow-steps-container .nav-tabs li a.step:hover h3.title {
 	color: #333;text-decoration: underline;
 }

.workflow-steps-container .workflow-title {
 	font-size: 13px;
 	text-transform: uppercase;
 	color: #465862;
 	text-align: center;
 	margin: 0 0 30px 0;
 	padding: 0;
 	letter-spacing: 0.3px
 }
 
.workflow-steps-container .all-features-page {
 	text-align: center;
 	border-bottom: 1px solid #e6f0f6
 }
 
.workflow-steps-container .all-features-page a {
 	background: #fff;
 	padding: 10px;
 	top: 12px;
 	position: relative;
 	color: #5c6f78 !important;
 	-webkit-transition: all;
 	transition: all;
 	-webkit-transition: all 0.3s ease;
 	transition: all 0.3s ease
 }
 
.workflow-steps-container .all-features-page a:hover {
 	color: #37474f !important
 }
 
.workflow-steps-container .all-features-page a:hover i {
 	color: #1999e3
 }

@media only screen and (max-width: 992px) {
	.header-bottom .features-wrap.open > a,
	.header-bottom .features-wrap.open > a:hover,
	.header-bottom .features-wrap.open > a:focus{
		background: #406eb3;
	}
	.features-menu{
		position: static;padding: 10px 0;border: 0;box-shadow: none;background: #406eb3;
	}
	
	.workflow-steps-container{padding: 0;}
	.workflow-steps-container .workflow-title,
	.workflow-steps-container .workflow-line,
	.workflow-steps-container a.step .icon,
	.workflow-steps-container li:not(.new-style).workflow-line,
	.workflow-steps-container li:not(.new-style) a.step .step-indicator{display: none;}
	.workflow-steps-container .all-features-page{
		border: none;text-align: left;
	}
	.workflow-steps-container ul.nav-tabs,
	.workflow-steps-container li:not(.new-style),
	.workflow-steps-container .nav-tabs li a.step{
		width: 100%;display: block;text-align: left;
	}
	.header-bottom .menu-top .workflow-steps-container .nav-tabs li{
		padding-left: 0;
	}
	.workflow-steps-container .nav-tabs li a.step{
		line-height: 30px;height: 30px;background: transparent !important;
	}
	    
	.workflow-steps-container .nav-tabs li a.step h3.title{color: #fff;padding-bottom: 0;margin: 0;border: 0;}
	.workflow-steps-container .nav-tabs li a.step h3.title p{display: none;}
	.workflow-steps-container .nav-tabs li a.step:hover h3.title{
		color: #fff;text-decoration: none;
	}
	.workflow-steps-container .all-features-page{
		line-height: 30px;height: 30px;
	}
	.workflow-steps-container .all-features-page a{position: static;padding: 0;}
}



















/*workflow-steps-container end*/


.header-bottom .dropmenu-wrap{
	position: absolute;
	left: 0;
	top: 90%;
	z-index: 1000;
	background: transparent;
	min-width: 280px;
	height: 0;
	opacity: .5;
	transition: all .5s linear;		
}
.header-bottom .dropmenu-wrap>ul{
	background: #fff;
	visibility: hidden;
	/*padding-bottom: 10px;*/
	margin-top: 10px;
    box-shadow: 0 3px 10px rgba(0,0,0,.17);
    border: 1px solid #d2d2d2;
    border-left: 2px solid #406eb3;
    -webkit-box-shadow: 0 3px 10px rgba(0,0,0,.17);
	transform: translateY(10%);
	transition: all .3s linear;	
	
}
.header-bottom .dropmenu-wrap>ul>li{
	line-height: 54px;
	padding: 0 10px;
    /*border-left: 2px solid #406eb3;;*/
}
.header-bottom .nav>li:hover .dropmenu-wrap{
	height: auto;
	max-height: 500px;
	opacity: 1;
}
.header-bottom .nav>li:hover .dropmenu-wrap>ul{
	visibility: initial;
	margin-top: 20px;
	transform: translateY(0);
}
.header-bottom .dropmenu-wrap>ul>li:hover{
	background: #f4f4f4;
}

.main-nav {
    /*font: 400 13px/20px "Open Sans","Helvetica","Arial",sans-serif;*/
    /*text-transform: uppercase;*/
    text-align: center;
    padding: 19px 0 14px;
}
.header-bottom .nav-utility,
.header-bottom .online-link{
	/*padding-top: 3px;*/
}
.header-bottom .nav-utility {
	padding-left: 0;
	font-size: 0;
}
.header-bottom .nav-utility a{
	margin-left: 25px;
}
/*.header-bottom .nav-utility .menu-drop a{
	margin-left: 0;
}*/
.header-bottom .nav-utility .avatar{position: relative;display: inline-block;}
.header-bottom .nav-utility .avatar .avatar-tip{line-height: 1;}
.header-bottom .nav-utility .avatar .avatar-tag{position: absolute;right: -5px;top: -2px;width: 15px;}

/*头像下的个人信息下拉列表*/
.av-info.dropdown-menu{
	width: 230px;
	line-height: 1.5;
	z-index:1111111;
}
.av-info.dropdown-menu .media{
	/*padding: 15px 15px;*/
	padding: 10px 3px 10px 18px;
}
.av-info.dropdown-menu .media-body div.name a,
.av-info.dropdown-menu .media-body p.desc{
	width: 100px;
	display: block;
}
.av-info.dropdown-menu .media-body div.name{position: relative;}
.av-info.dropdown-menu .media-body div.name .name-tag{position: absolute;right: 0;top: 0;}

.av-info.dropdown-menu .media-body p.desc a{
	display: block;
	color: #406eb3;;
}
.av-info.dropdown-menu .media-left{
	padding-right: 10px;
}
.av-info.dropdown-menu .media-left img{
	width: 40px;
}

.av-info.dropdown-menu  a{
	color: #333;
	margin: 0;
}
.av-info.dropdown-menu dl{
	margin-bottom: 10px;
}
.av-info.dropdown-menu dt{
	/*height: 20px;*/
	line-height: 28px;
	border-top: 1px solid #d2d2d2;
	background: #eee;
	padding-left: 15px;
	margin-bottom: 5px;
	font-size: 14px;
	font-weight: 500;
}
.av-info.dropdown-menu dd{
	/*line-height: 19px;*/
	line-height: 26px;
	padding-left: 30px;
	font-size: 14px;
}
.av-info.dropdown-menu dd a{
	
}
.av-info.dropdown-menu dd .fa-rocket{
	color: #f67426;
}
.av-info.dropdown-menu .item-list dd:hover{
	background: #eee;
}
.av-info.dropdown-menu .sign-out{
	margin-bottom: 2px;
}
.av-info.dropdown-menu .item-list dd:not(.not-el) a,
.av-info.dropdown-menu .sign-out a{
	display: block;
}

.av-info-h5.dropdown-menu{
	width: 170px;
}
.av-info-h5.dropdown-menu dl{
	margin-bottom: 0;
}
/*头像下的个人信息下拉列表 end*/
@media only screen and (min-width:992px ) {
	.header-bottom .nav-utility .avatar .avatar-tag{top: -5px;width: 20px;}
}
.header-top .online-link,.header-top .nav-utility{
	height: 40px;
    line-height: 40px;
    float: right;
}
.header-bottom .online-link,.header-bottom  .nav-utility{
	height: 54px;
    line-height: 54px;
    float: right;
    /*margin-top: 4px;*/
}
.header-bottom .online-link{
	 /*margin-top: 6px;*/
}
.nav-utility {
    text-align: right;
    /*padding-left: 32px;*/
}
.nav-utility .socialnetworks {
    overflow: hidden;
    text-align: left;
    margin: 0;
    padding: 0;
    font-size: 0;
    list-style: none;
}
.nav-utility .socialnetworks li{
	padding-right: 0;
	padding-left: 0;
}
.nav-utility .socialnetworks li > a > img{
	width: 28px;
}
.nav-utility .socialnetworks .icon{
	/*font-size: 26px;*/
	
}

.header-bottom .online-link,.header-bottom .online-link i {
	/*padding-top: 5px;*/
	font-size: 26px;
}

.online-link {
    /*text-transform: uppercase;*/
    padding: 0 0 0 0;
}
.online-link a{
	padding-right: 32px;
}

.header-tabs {
	/*padding-left: 3px;*/
    /*text-transform: uppercase;*/
    /*border-bottom: 0;*/
    color: #434343;
}

@media only screen and (min-width: 992px) {
	.notLogin.upgrade{display: none;}
	.avatar.notLogin{display: none !important;}
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.header .container{width: 100%;padding: 0 15px;}
	.header .logo { 
    	width: 142px;
	}
	.header-bottom .menu-top > ul > li{
		margin-right: 28px;
	}
	
}
@media only screen and (min-width: 992px) and (max-width: 1080px) {
	.header-bottom .menu-top > ul > li{
		margin-right: 15px;
	}
	.header-bottom .menu-top .nav {
	    margin-left: 0;
	}
	.header-bottom,.header-top {
	    padding-left: 15px;
    }
    .header-bottom .online-link {
		    max-width: 190px;
	}
}
@media (max-width: 992px) {
    .header .logo { 
    	width: 100px;
	}
	.header-top .menu-top ul li a{
		padding: 5px 8px;

	}
	.header-bottom .menu-top .nav {
	     margin-left: 0px; 
	}
	.header-bottom .menu-top ul li {
	    margin-right: 1px;
	}
	.header-bottom .menu-top ul li a{
		padding: 10px 0px;
	}
	.header-top .nav-utility {
	    height: 30px;
	    line-height: 30px;
	    float: right;
	}
	.header-bottom .online-link,.header-bottom  .nav-utility{
		height: 40px;
	    line-height: 40px;
	    float: right;
	}
	.header-bottom .online-link{
		width: 150px;
	}
	.header-bottom .nav-utility .btn{
		padding: 5px 10px;
	}
}



.footer{
	border-top: 3px solid #406eb3;
	
	padding-top: 20px;
	font-size: 14px;
}
.footer dl dt,.footer dl dd{
	line-height: 32px;
}
.footer dl dt,
.footer dl dt a{
	font-size: 20px;
	color: #406eb3;
	font-weight: 400;
}
.footer dl dd,
.footer dl dd a{
	color: #999;
	font-size: 14px;
}
.footer dl.last dt{
	color: #333;
}
.footer-logo{
	display: table;
	/*height: 160px;*/
	height: 130px;
}
.footer-logo > div{
	display: table-cell;
	vertical-align: middle;
}
.footer dl{
	width: 150px;
	display: inline-block;
}
.footer dl dt,
.footer dl dd{
	text-align: left;	  			
}
.footer .row>div{text-align: center;}

.footer-bottom{
	
	margin-top: 20px;
}
.footer-bottom .container{
	padding: 20px 0;
	border-top: 2px solid #ccc;
}
.footer-bottom ul li{
	padding: 0;
}
.footer-bottom .copy{text-align: left;}

@media only screen and (min-width:992px ) {
	body{padding-top: 102px;}
	.header-bottom .online-link{display: table;}
	.header-bottom .online-link>div{display: table-cell;vertical-align: middle;}
	
	.footer-bottom .copy{text-align: center;}
	
}